/* eslint-disable */
import React from "react";
import "./Meg.css";
import mittBilde from "../../assets/meg.svg";

function Home() {
  return (
    <div id="colorlib-main">
      <section className="ftco-section pt-4 mb-5 ftco-intro">
        <div className="container-fluid px-3 px-md-0">
          <div className="row gy-3 gy-md-4 gy-lg-0 align-items-md-start .d-flex">
            <div className="col-12 col-md-6">
              <h1 className="h2">Hey!</h1>
              <p className="text-dark">
                Jeg heter Sultan Avtajev og er en dataingeniørstudent ved
                OsloMet. Jeg avslutter min utdanning ved OsloMet sommeren 2024.
                Denne nettsiden fungerer som min personlige cv. Her kan du finne
                informasjon om min utdanning, erfaring, ferdigheter og
                kontaktinformasjon.
              </p>
              <p className="text-dark">
                Velkommen til denne siden. Her kommer jeg også til å dele mine
                tanker, ideer og prosjekter jeg til enhver tid holder på med.
                Naviger rundt på denne siden og bli enda bedre kjent med meg!
              </p>
            </div>

            <div class="col-12 col-md-6 text-primary">
              <img src={mittBilde} alt="Bilde av meg" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
